<footer class="footer ">
  <div class="container-fluid">
    <nav class="pull-left">
      <ul>
        <li>
          <a href="#">
            Dashboard
          </a>
        </li>
        <li>
          <a href="#">
            Login
          </a>
        </li>
        <li>
          <a href="#">
            Contáctenos
          </a>
        </li>
        <li>
          <a href="#">
            Políticas y Condiciones
          </a>
        </li>
      </ul>
    </nav>
    <div class="copyright pull-right">
      &copy;
        {{test | date: 'yyyy'}}, desarrollado por
      <a href="https://pakki.co" target="_blank">Pakki - Compras y Envíos</a>.
    </div>
  </div>
</footer>
