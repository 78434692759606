<div class="logo bg-white text-center">
  <!-- <a class="simple-text logo-mini bg-white">
    <div class="logo-img bg-white">
      <img src="/assets/img/angular2-logo-white.png" />
    </div>
  </a> -->
  <a href="https://pakki.co" class="simple-text logo-normal">
    <img src="/assets/img/logo_pakki_fondo_blanco.png" />
  </a>
</div>

<div class="sidebar-wrapper">
  <div class="user">
    <div class="photo">
      <img src="./assets/img/default-avatar.png" />
    </div>
    <div class="user-info">
      <a data-toggle="collapse" href="#collapseExample" class="collapsed">
        <span>
          {{ user?.collaborator?.name }} {{ user?.collaborator?.lastName }}
          <b class="caret"></b>
        </span>
        <span>
          {{
            selectsucursal?.tradename
              ? selectsucursal?.tradename
              : user.business.branchOffices[0].tradeName
          }}
        </span>
      </a>
      <div class="collapse" id="collapseExample">
        <ul class="nav">
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link">
              <span class="sidebar-mini">MP</span>
              <span class="sidebar-normal">Mi Perfil</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link">
              <span class="sidebar-mini">EP</span>
              <span class="sidebar-normal">Editar Perfil</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link">
              <span class="sidebar-mini">C</span>
              <span class="sidebar-normal">Configuración</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="isMobileMenu()">
    <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input
            type="text"
            value=""
            class="form-control"
            placeholder="Buscar..."
          />
          <button
            mat-raised-button
            type="submit"
            class="btn btn-white btn-round btn-just-icon"
          >
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form>
    <ul class="nav navbar-nav nav-mobile-menu">
      <li class="nav-item">
        <a class="nav-link" href="#pablo">
          <i class="material-icons">dashboard</i>
          <p>
            <span class="d-lg-none d-md-block">Stats</span>
          </p>
        </a>
      </li>
      <li class="nav-item dropdown">
        <a
          class="nav-link"
          href="#pablo"
          id="navbarDropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="material-icons">notifications</i>
          <span class="notification">5</span>
          <p>
            <span class="d-lg-none d-md-block">Some Actions</span>
          </p>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <a class="dropdown-item" href="#">Transacción por verificar</a>
          <a class="dropdown-item" href="#">5 tareas por verificar</a>
          <a class="dropdown-item" href="#">Un mensaje por leer</a>
          <a class="dropdown-item" href="#">Notificación sin leer</a>
          <a class="dropdown-item" href="#">Petición pendiente</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#pablo">
          <i class="material-icons">person</i>
          <p>
            <span class="d-lg-none d-md-block">Cuenta</span>
          </p>
        </a>
      </li>
    </ul>
  </div>
  <ul class="nav">
    <li
      routerLinkActive="active"
      *ngFor="let menuitem of menuItems"
      class="nav-item"
    >
      <!--If is a single link-->
      <a
        [routerLink]="[menuitem.path]"
        class="nav-link"
        *ngIf="menuitem.type === 'link'"
      >
        <i class="material-icons">{{ menuitem.icontype }}</i>
        <p>{{ menuitem.title }}</p>
      </a>
      <!--If it have a submenu-->
      <a
        data-toggle="collapse"
        href="#{{ menuitem.collapse }}"
        *ngIf="menuitem.type === 'sub'"
        (click)="updatePS()"
        class="nav-link"
      >
        <i class="material-icons">{{ menuitem.icontype }}</i>
        <p>{{ menuitem.title }}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div
        id="{{ menuitem.collapse }}"
        class="collapse"
        *ngIf="menuitem.type === 'sub'"
      >
        <ul class="nav">
          <li
            routerLinkActive="active"
            *ngFor="let childitem of menuitem.children"
            class="nav-item"
          >
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{ childitem.ab }}</span>
              <span class="sidebar-normal">{{ childitem.title }}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <!-- <li class="nav-item">
            <a href="#" class="nav-link">
                <i class="material-icons">local_police</i>
                <p>Políticas y Condiciones</p>
            </a>
        </li> -->
  </ul>
</div>
