<div class="wrapper">
  <div
    class="sidebar"
    data-color="orange"
    data-background-color="white"
    data-image="./assets/img/sidebar-2.jpg"
  >
    <app-sidebar-cmp></app-sidebar-cmp>
    <div class="sidebar-background"></div>
  </div>
  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>
    <router-outlet></router-outlet>
    <div *ngIf="!isMap()">
      <app-footer-cmp></app-footer-cmp>
    </div>
  </div>
  <app-fixedplugin></app-fixedplugin>
</div>
